import {TrackingFeatureLabels} from "../types";
import DiscoveryCardTile from "./discovery_card_tile";
import GenericTrackingService, {TrackingWidgetTile} from "../generic_tracking_service";

export default class BrandhubDiscoveryCard {
    readonly TRACKING_PROMO_TYPE = "BrandhubDiscoveryCard";
    readonly featureOrder: number;
    readonly filledSlots: number;
    readonly featureIndex: number;
    readonly featureId: string;
    readonly parentFeatureId: string;
    readonly trackingWidgetTiles: TrackingWidgetTile[];
    readonly masFeatureTrackingLabels: TrackingFeatureLabels;
    private readonly trackingService: GenericTrackingService;

    constructor(container: Element) {

        this.featureOrder = this.getFeatureOrder(container);
        this.masFeatureTrackingLabels = this.getMasFeatureTrackingLabels(container);
        this.featureIndex = this.getFeatureIndex(container);
        this.featureId = this.getDataFeatureId(container)
        this.parentFeatureId = this.getParentFeatureTrackingId(this.featureId);
        const discoveryCardTileDoms = container.querySelectorAll('.promo_brandhub_discovery_card--item-large, .promo_brandhub_discovery_card--item-small, .promo_brandhub_discovery_card--cta-button');
        this.trackingWidgetTiles = Array
            .from(discoveryCardTileDoms)
            .map((tileDom: Element, index: number) => new DiscoveryCardTile(tileDom, index + 1))
            .map(discoveryCardTile => this.convertToTrackingWidgetTile(discoveryCardTile))

        this.filledSlots = this.trackingWidgetTiles.length;
        this.trackingService = new GenericTrackingService();
        this.trackLoaded(container);
        this.registerScrollTrackingObserver(discoveryCardTileDoms);


        discoveryCardTileDoms.forEach((discoveryCardTileDom: Element, index: number) => {
            discoveryCardTileDom.addEventListener("click", this.trackClick(this.trackingWidgetTiles[index]), false);
        });
    }

    trackClick(trackingWidgetTile: TrackingWidgetTile): () => void {
        const self = this;
        return function () {
            self.trackingService.sendClickTrackingEvent(self.featureOrder, self.featureIndex, self.parentFeatureId, trackingWidgetTile, self.filledSlots, self.masFeatureTrackingLabels, self.TRACKING_PROMO_TYPE);
        }
    }

    registerScrollTrackingObserver(brandHubDiscoveryCards: NodeListOf<Element>): void {
        /*                                     */
        const trackingObserver = new IntersectionObserver((entries) => {
            const entriesToTrack = entries
                .filter((entry) => entry.isIntersecting);

            if (entriesToTrack.length > 0) {
                const positionsToTrack = entriesToTrack
                    .map((entry) => this.getTileFeatureTrackingId(this.featureId, parseInt(entry.target.getAttribute("data-tile-position")!)))

                this.trackingService.sendScrollTrackingEvent(this.parentFeatureId, positionsToTrack);

                entriesToTrack.forEach((entry) => {
                    trackingObserver.unobserve(entry.target);
                });
            }

        });
        brandHubDiscoveryCards.forEach((card) => {
            trackingObserver.observe(card);
        });
    }

    private getFeatureOrder(container: Element): number {
        const dataFeatureOrder = container.closest('[data-feature-order]')?.getAttribute('data-feature-order');
        return dataFeatureOrder ? parseInt(dataFeatureOrder, 0) : -1;
    }

    private getMasFeatureTrackingLabels(container: Element): TrackingFeatureLabels {
        const dataFeatureTrackingLabels = container.closest('[data-feature-tracking-labels]')?.getAttribute("data-feature-tracking-labels");
        return dataFeatureTrackingLabels ? JSON.parse(dataFeatureTrackingLabels) : {};
    }

    private getFeatureIndex(container: Element): number {
        return parseInt(container.getAttribute('data-feature-index')!, 0);
    }

    private getDataFeatureId(container: Element): string {
        return container.getAttribute('data-feature-id')!;
    }

    private trackLoaded(container: Element) {
        if (!container.getAttribute("data-tracked")) {
            container.setAttribute("data-tracked", "true");
            this.trackingService.sendLoadedTracking(this.featureOrder,
                this.featureIndex,
                this.parentFeatureId,
                this.trackingWidgetTiles,
                this.masFeatureTrackingLabels,
                this.TRACKING_PROMO_TYPE);
        }
    }

    private getParentFeatureTrackingId(featureId: string) {
        return `ft3_bhdc_${featureId}`;
    }

    private getTileFeatureTrackingId(featureId: string, tilePosition: number) {
        return `ft3_bhdc_${featureId}_tile${tilePosition}`;
    }

    private convertToTrackingWidgetTile(discoveryCardTile: DiscoveryCardTile): TrackingWidgetTile {
        return {
            id: this.getTileFeatureTrackingId(this.featureId, discoveryCardTile.position),
            position: discoveryCardTile.position,
            labels: {
                promo_Content: [discoveryCardTile.urlPath],
                promo_TargetPsr: [discoveryCardTile.dreson],
                promo_Source: ["manual"],
                promo_ImageId: [discoveryCardTile.imageId],
                promo_DiscoverTileType: [discoveryCardTile.tileType]
            }
        }
    }

}